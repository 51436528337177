import OnboardingLayout from '@/layouts/OnboardingLayout';
import Head from 'next/head';

function Custom404<NextComponentWithLayout>() {
  return (
    <div className="w-full flex justify-center absolute-vertical-center">
      <Head>
        <title>404 Page Not Found | Bloom</title>
      </Head>
      <div className="flex flex-col justify-center items-center md:w-[496px] h-full">
        <div className="flex justify-center items-center bg-secondary w-full h-[120px] rounded-xl text-center p-8">
          <h1 className="text-2xl font-bold text-center">404 - Page Not Found</h1>
        </div>
      </div>
    </div>
  );
}

export default Custom404;

Custom404.getLayout = OnboardingLayout;
